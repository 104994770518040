import React, {useContext, useEffect, useState} from "react";
import {rgba} from "polished";
import {Container, Row, Col} from "react-bootstrap";
import {Section, Box, Text, Title, Button} from "../../components/Core";
import styled from "styled-components";
import {thousands_separators} from "../../utils/helperFn";
import GlobalContext from "../../context/GlobalContext";
import CheckAvailability from "../../sections/index/CheckAvailability";
import ukmap from "../../assets/image/svg/ukmap-main.svg";
import fttp from "../../assets/image/svg/fttp.svg";
import {graphql, navigate, useStaticQuery} from "gatsby";
import trustPilotImg from "../../assets/image/png/yf__box_new.png"
import rowBgImg from "../../assets/image/png/yf__pozadina.png"
import Cities from "../../assets/map/uk-regions/Cities";
import {SVGMap,RadioSVGMap} from "react-svg-map";
import UKRegions from "../../assets/map/uk-regions/uk-regions";
import Link from "gatsby-link";

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;
const WidgetStyled = styled(Box)`
  padding-left: 60px;
  padding-bottom: 40px;
  position: relative;
  
  &:before {
    counter-increment: widget-counter;
    content: counter(widget-counter);
    position: absolute;
    left: 0;
    min-width: 40px;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 500px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.colors.primary};
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.75px;
    
  }
`;
const rowBgImgStyle = {
	backgroundImage: `url(${rowBgImg})`,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
};

const Widget = ({num = 1, title = "", children = "", ...rest}) => {
    return (
        <WidgetStyled {...rest}>
            <Title style={{color:'black', fontSize:'2em', lineHeight:'1.1em'}} className="mb-2">
                {title}
            </Title>
            <Text variant="small" style={{color: 'black', fontSize: '1.2em', lineHeight:'1.6em'}}>{children}</Text>
        </WidgetStyled>
    );
};
const FeatureCard = ({
                         color = "primary",
                         iconName,
                         title,
                         children,
                         checkButton,
                         ...rest
                     }) => {


    const gContext = useContext(GlobalContext)

    return(
    <Box
        width={"100%"}
        bg="light"
        p="30px"
        borderRadius={10}
        style={{boxShadow: '0 6px 20px rgb(15 12 21 / 10%)'}}
        {...rest}>

        <Row>

            <Col sm={12} md={1}>
                <Box
                    size={69}
                    minWidth={69}
                    minHeight={69}
                    borderRadius="50%"
                    color={color}
                    fontSize="28px"
                    className="d-flex justify-content-center align-items-center"
                    mr="20px"
                    css={`
                      background-color: ${({theme, color}) =>
                              rgba(theme.colors[color], 0.1)};
                    `}
                >
                    <i className={iconName}/>
                </Box>
            </Col>
            <Col sm={12} md={11} className="pl-5">
                <Text
                    color="heading"
                    as="h3"
                    fontSize="1.5em"
                    fontWeight={500}
                    letterSpacing={-0.75}
                    mb={0}
                >
                    {title}
                </Text>
                <Text fontSize="1.2em" lineHeight={1.75}>
                    {children}
                </Text>
            </Col>
        </Row>
    </Box>
)
}

const SectionStyled = styled(Section)`
  background-image: linear-gradient(181deg, rgb(239 239 239) 60%, rgba(84, 84, 212, 0) 100%);
`;

const renderStatusTitle = (Status) => {
    switch (Status) {
        case "live":
            return "Live"
        case "in_progress":
            return "In Build"
        case "planned":
            return "Plan"
        default:
            return "Plan"
    }
}


const renderStatusIconColor = (Status) => {
    if (Status && Status === 'live') {
        return "success"
    } else if (Status && Status === 'in_progress') {
        return "info"
    } else {
        return "primary"
    }
}

const renderStatusIcon = (Status) => {
    if (Status && Status === 'live') {
        return "fa fa-check"
    } else if (Status && Status === 'in_progress') {
        return "fa fa-clock"
    } else if (Status && Status === 'planned') {
        return "fa fa-drafting-compass"
    } else {
        return "fa fa-drafting-compass"
    }
}

const renderStatusText = (Status, CityName) => {
    if (Status && Status === 'live') {
        return "FTTP network is now live!"
    } else if (Status && Status === 'in_progress') {
        return "We are building our FTTP network in your area"
    } else if (Status && Status === 'planned') {
        return "We are planning our FTTP network in your area"
    } else {
        return "We are planning our FTTP network in your area"
    }
}

const renderCheckAvailability = (Status) => {
    if (Status && (Status === 'live' || Status === 'in_progress')) {
        return (
            <Col sm={12} md={9} className="mt-4 mx-auto">
                <Container>
                    <Row className="justify-content-center text-center">
                        <CheckAvailability
                            hideLogo={true}
                            customTitle={""}
                            isFocused={false}
                        />
                    </Row>
                </Container>
            </Col>
        )
    }
}

const LocalStats = ({Size, Investment, Status, CityName, All}) => {
    const data = useStaticQuery(graphql`
       query getAvailableRegionsLSQuery {
        allStrapiRegions(filter: {towns: {elemMatch: {id: {gt: 0}}}}) {
            edges {
              node {
                  id
                  Slug
                  Name
              }
            }
          }
        }
    `)
    const netomniaRegions = data.allStrapiRegions.edges.map(strapiRegion => strapiRegion.node);
    const allUkRegions = UKRegions.mapData.map(region => ({
        name: region.name,
        id: region.id,
        path: region.path,
    }))
    const map = {
        "label": "Map of UK Regions",
        "viewBox": "-679.3 146.4 327.7 370.6",
        "locations": allUkRegions
    }
    const getLocationClassName = (location) => {
        console.log('getLocationClassName', location);
        return netomniaRegions.find(x => x.Slug === 'wales') ? 'svgregion active' : 'svgregion disabled'
    }
    const handleMapClick = (event) => {
        console.log('handleMapClick event', event);
    };

    const [isHovered, setIsHovered] = useState(false);

    const buttonBgImgStyle = {
        cursor: 'pointer',
        background: '#00ff85',
        padding: '0.85rem 1.75rem',
        minWidth: '200px',
        borderRadius: isHovered ? '12px' : '22px',
        fontSize: '21px',
        fontWeight: '500',
        color: 'black',
        transition: 'all 0.5s',
    };

    /*get element by id*/
    useEffect(()=>{
        const element = document.getElementById(All.strapiTowns.Slug);
        if (element) {
            element.style.fill = 'red';
            element.style.r = '5';
            element.stroke = 'black'
        }
    })
    return(
    <>
        <SectionStyled>
            <Container>
                <Row className="align-items-center">
                    <Col lg="12" className="text-center">
                        <Title>{CityName} Full Fibre Network</Title>
                    </Col>

                    <Row className="align-items-center">
                        <Col sm={12} md={5} lg={5} className="mt-3 mt-lg-4 interactive-map-wrapper">
                            {/*<img alt="" src={ukmap}/>*/}
                            <SVGMap
                                map={map}
                                onChange={(e) => handleMapClick(e.id)}
                                selectedLocationId={All?.strapiTowns?.region?.Slug}
                                locationClassName={getLocationClassName}
                                childrenAfter={<Cities filterData={All?.strapiTowns?.region?.Slug}/>}
                            />
                        </Col>
                        <Col sm={12} md={7} lg={7} >
                            <Row>
                                <Col sm={12} md={12} className="mt-4">
                                    <FeatureCard color="secondary" iconName="fa fa-home" title={`Connectivity`}>
                                        {`Improving connectivity for over ${thousands_separators(Size)} homes and businesses`}
                                    </FeatureCard>
                                </Col>

                                <Col sm={12} md={12} className="mt-4">
                                    <FeatureCard color="ash" iconName="fa fa-funnel-dollar" title="Investment">
                                        {`Up to £${thousands_separators(Investment)} will be invested to deliver the infrastructure`}
                                    </FeatureCard>
                                </Col>

                                <Col sm={12} md={12} className="mt-4">
                                    <FeatureCard color={renderStatusIconColor(Status)} iconName={renderStatusIcon(Status)}
                                                 title={renderStatusTitle(Status)} checkButton={Status === 'live'}>
                                        {renderStatusText(Status, CityName)}
                                    </FeatureCard>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/*{ renderCheckAvailability(Status) }*/}
                </Row>

            </Container>
            <Container className="my-5">
				<Row className="align-items-center mb-5 justify-content-center">
					<Col lg="12" className="text-center">
						<Title>We're An FTTP Network Builder</Title>
					</Col>
					<Col lg="9">
						<Text className="text-center">At Netomnia, we are an independent full fibre network builder. This
							means that we put the fibre optic cables in the ground and our
							partner ISP will provide you with your package.</Text><br/>
						<Text className="text-center">Being separate from other networks (such as Openreach and Virgin)
							means that we are able to provide you with even more choice when it
							comes to your broadband.</Text><br/>
						<Text className="text-center">You don’t have to be served by the same, slow, unresponsive
							broadband provider when your contract ends anymore.</Text>
					</Col>
				</Row>
                <Row className="align-items-center">
                    <Col lg="12" className="text-center">
                        <Title>What is Full Fibre Broadband</Title>
                    </Col>
                    <Col sm={12} lg={12} className="p-3 mt-5 mt-md-0">
                        <img alt="" src={fttp}/>
                    </Col>
                    <Col sm={12} lg={12}>
                        <Text className="text-center">
                            Full fibre broadband is a new type of broadband that is made of entirely fibre optic cables.<br/>
                            Currently, a lot of the broadband networks across the UK are made of both fibre optic cables
                            AND copper cables. This means you aren’t receiving the fastest possible speeds!
                        </Text>
                    </Col>
                </Row>
            </Container>
			<Container fluid style={rowBgImgStyle}>
				<Container className="my-5">
					<Row className="py-5">
						<Col lg="7">
							<Title color="white">Our ISP Partners</Title>
							<Text color="white">Because we are only the full fibre network builder (and not the internet service
								provider), it means that we are unable to sell you broadband.
							</Text><br/>
							<Text color="white">This means that if you are looking for a new broadband package over our network, you
								must go through our ISP partners.
							</Text><br/>
							<Text color="white">
								YouFibre offer incredible packages for low-costs. Their customer service is responsive
								and able to help you through any situation. Don’t believe us? Their 4.9 Trustpilot score
								speaks for itself.
							</Text><br/>
							<Text color="white" className="mb-3">VISIT YOUFIBRE HERE.</Text>
                            <a
                                href="https://youfibre.com/"
                                style={buttonBgImgStyle}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                VIEW PACKAGES AND PRICES
                            </a>
						</Col>
						<Col lg="5">
							<img alt="" src={trustPilotImg} className="img-fluid"/>
						</Col>
					</Row>
				</Container>
			</Container>
            <Container className="my-5">
                <Col lg="12" className="text-center">
                    <Title>Benefits of Full Fibre Broadband</Title>
                </Col>
                <Col className="text-center">
                    <WidgetContainer mt={5}>
                        <Col
                            md="12"
                            data-aos="fade-right"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <Widget mb={3} className="text-left">
                                Faster speeds – receive internet speeds that are lightning fast and that can handle all of the devices in your home, even in the peak hours.
                            </Widget>
                        </Col>
                        <Col
                            md="12"
                            data-aos="fade-right"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <Widget mb={3} className="text-left">
                                Ultra-reliable - you won’t have to worry about seeing a flashing red light ever again.
                            </Widget>
                        </Col>

                        <Col
                            md="12"
                            data-aos="fade-right"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <Widget mb={3} className="text-left">
                                Clearer video calls – whether you work from home, or just keep in touch with loved ones often, you can have crystal clear video and audio calls.
                            </Widget>
                        </Col>
                    </WidgetContainer>
                </Col>
                <Col className="text-center">
                    <Button
                        style={{
                            background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                            border: 'none',
                            width: 'auto'
                        }}
                        className="btn-red hvr-bounce-to-left"
                        onClick={() => navigate('/check-availability')}
                    >
                        Check Availability
                    </Button>
                </Col>
            </Container>
        </SectionStyled>
    </>
    )
}

export default LocalStats;
